import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import Slider from 'react-slick';

import Link from 'next/dist/client/link';
import { WIDTH } from '../../../constants/screenResolution';
import { useAnalytics } from '../../../contexts/AnalyticsProvider';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import { WE_USER_EVENT_GIFTCARD_CLICKED } from '../../../utils/we';
import { sliderSettings } from '../NewStore';
import {
  ImageItemTile,
  ItemsSliderContainer,
  MobileSlider,
  SeeThroughTag,
  StoreSection,
} from '../NewStoreStyles';
import { giftcardsByPlatform } from '../data';
import { useLocale } from '../../../contexts/LocalizationContext';

const GiftcardsByPlatform: React.FC = () => {
  const { width } = useWindowDimensions();
  const router = useRouter();
  const { cleverTap } = useAnalytics();
  let {messages} = useLocale();
  const { gift_card_msg } = messages || {};
  return (
    <StoreSection>
      <h3>
        {width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
          ? 'Explore'
          : ''}{' '}
        Giftcards by Platform
      </h3>
      <ItemsSliderContainer>
        {width && width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
          <Slider {...sliderSettings}>
            {giftcardsByPlatform.map((cur, i) => (
              <div key={i}>
                <Link passHref href={cur.url}>
                  <a style={{ padding: '0' }}>
                    <ImageItemTile
                      onClick={() => {
                        WE_USER_EVENT_GIFTCARD_CLICKED(
                          {
                            giftCardName: cur.title,
                            pageUrl: window.location.href,
                            link: cur.url,
                          },
                          cleverTap
                        );
                        saveEventV3({
                          category: 'collection_card',
                          action: 'click',
                          label: 'giftcards_by_platform',
                          properties: (router.query.group as string) ?? '',
                          value: [cur.title],
                          from: router,
                        });
                      }}
                    >
                      <Image src={cur.imageUrl} alt={cur.title} layout="fill" />
                      <SeeThroughTag>{gift_card_msg}</SeeThroughTag>
                    </ImageItemTile>
                  </a>
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <MobileSlider>
            {giftcardsByPlatform.map((cur, i) => (
              <div key={i}>
                <Link passHref href={cur.url}>
                  <a>
                    <ImageItemTile
                      onClick={() => {
                        WE_USER_EVENT_GIFTCARD_CLICKED(
                          {
                            giftCardName: cur.title,
                            pageUrl: window.location.href,
                            link: cur.url,
                          },
                          cleverTap
                        );
                        saveEventV3({
                          category: 'collection_card',
                          action: 'click',
                          label: 'giftcards_by_platform',
                          properties: (router.query.group as string) ?? '',
                          value: [cur.title],
                          from: router,
                        });
                      }}
                    >
                      <Image src={cur.imageUrl} alt={cur.title} layout="fill" />
                      <SeeThroughTag>Gift card</SeeThroughTag>
                    </ImageItemTile>
                  </a>
                </Link>
              </div>
            ))}
          </MobileSlider>
        )}
      </ItemsSliderContainer>
    </StoreSection>
  );
};

export default GiftcardsByPlatform;
