import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const NRBWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 0 0;
  gap: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    gap: 12px;
    // padding: 0 16px;
    margin-left: 0px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 0px;
    // padding: 0 16px;
  }
`;

interface NRBItemInterface {
  src: string;
}

export const NRBItem = styled.a`
  display: flex;
  height: 151px;
  cursor: pointer;
  flex-wrap: wrap;
  width: 32%;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  box-shadow: ${(props) => props.theme.shadows.s2};
  flex: 1;
  overflow: hidden;
  width: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    // width: 49%;
    flex: 1;
    // height: 128px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    display: flex;
    flex: none;
    height: 128px;
    width: 100%;
  }
`;

export const NRBImage = styled.img`
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.3s all ease;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    &:hover {
      transform: scale(1);
    }
  }
`;
