import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { WIDTH } from '../../../constants/screenResolution';
import { useAnalytics } from '../../../contexts/AnalyticsProvider';
import { useLocale } from '../../../contexts/LocalizationContext';
import { getFilterList } from '../../../helper/helper';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useAppSelector } from '../../../redux/hooks';
import { saveEventV3 } from '../../../utils/eventTracking';
import { formatBigNumber } from '../../../utils/formatBigNumber';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import { WE_USER_EVENT_EXPLORE_BY_PRICE_CLICKED } from '../../../utils/we';
import { ItemsContainer, PriceTile, StoreSection } from '../NewStoreStyles';

const GamesUnderPrice = () => {
  const global = useAppSelector((state) => state.global);
  const router = useRouter();
  const { width } = useWindowDimensions();
  const { cleverTap } = useAnalytics();

  const { messages } = useLocale();
  const { games_under_price_msg, under_msg } = messages || {};

  const [rangeList, setRangeList] = useState<number[]>([]);

  useEffect(() => {
    if (global.locationDetails) {
      const list = getFilterList(global.locationDetails.multiplier);
      setRangeList(list);
    }
  }, [global.locationDetails]);

  const handleTileClick = useCallback(
    (maxPrice: number, minPrice: number) => {
      const maxPriceFinal = (maxPrice / global.locationDetails.multiplier)
        .toFixed(2)
        .toString();
      const minPriceFinal = (minPrice / global.locationDetails.multiplier)
        .toFixed(2)
        .toString();
      router.push({
        pathname: '/store',
        query: {
          page: 1,
          priceMax: maxPriceFinal,
          priceMin: minPriceFinal,
          sort: 'l2h',
        },
      });
    },
    [global.locationDetails]
  );

  return (
    <StoreSection style={{ paddingBottom: 0 }}>
      <h3
        style={
          width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
            ? {}
            : { marginBottom: 0 }
        }
      >
        {games_under_price_msg}
      </h3>
      <ItemsContainer
        style={
          width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
            ? {}
            : {
                overflow: 'auto',
                flexWrap: 'nowrap',
                paddingTop: '12px',
                paddingBottom: '24px',
              }
        }
      >
        {rangeList.slice(0, 5).map((cur, i) => (
          <PriceTile
            key={i}
            onClick={() => {
              WE_USER_EVENT_EXPLORE_BY_PRICE_CLICKED(
                {
                  priceUnderWithCurrency:
                    global.locationDetails?.currency_symbol ||
                    'EUR' + formatBigNumber(cur),
                  currency: global.locationDetails?.currency_symbol || 'EUR',
                  pageUrl: window.location.href,
                },
                cleverTap
              );
              saveEventV3({
                category: 'collection_card',
                action: 'click',
                label: 'games_under_price',
                properties: (router.query.group as string) ?? '',
                value: [
                  'under ' + global.locationDetails?.currency_symbol ||
                    'EUR' + '' + formatBigNumber(cur),
                ],
                from: router,
              });
              handleTileClick(cur, rangeList[i - 1] || 0);
            }}
          >
            {under_msg} {global.locationDetails?.currency_symbol || 'EUR'}
            {formatBigNumber(cur)}
          </PriceTile>
        ))}
      </ItemsContainer>
    </StoreSection>
  );
};

export default GamesUnderPrice;
