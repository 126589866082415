import React from 'react';

import Link from 'next/dist/client/link';
import { useRouter } from 'next/router';
import { useLocale } from '../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../utils/eventTracking';
import { StoreHeaderButton, StoreHeaderContainer } from '../NewStoreStyles';

const storeGroups = [
  {
    name: 'All Products',
    value: 'all',
  },
  {
    name: 'Explore',
    value: 'explore',
  },
  {
    name: 'Games',
    value: 'games',
  },
  {
    name: 'Giftcards',
    value: 'giftcards',
  },
  {
    name: 'Game points',
    value: 'gamepoints',
  },
];

interface StoreHeaderPropsI {
  selectedType: string;
}

const StoreHeader: React.FC<StoreHeaderPropsI> = ({ selectedType }) => {
  const router = useRouter();
  let { messages } = useLocale();
  const {
    all_products_msg,
    explore_msg,
    games_msg,
    giftcards_msg,
    game_points_msg,
  } = messages || {};

  const storeGroups = [
    {
      name: all_products_msg,
      value: 'all',
    },
    {
      name: explore_msg,
      value: 'explore',
    },
    {
      name: games_msg,
      value: 'games',
    },
    {
      name: giftcards_msg,
      value: 'giftcards',
    },
    {
      name: game_points_msg,
      value: 'gamepoints',
    },
  ];
  return (
    <>
      <StoreHeaderContainer>
        {storeGroups.map((cur, i) => (
          <Link passHref href={'/store?group=' + cur.value} key={i}>
            <a>
              <StoreHeaderButton
                selected={selectedType === cur.value}
                onClick={() => {
                  saveEventV3({
                    category: 'store_group',
                    action: 'click',
                    label: cur.value,
                    properties: '',
                    value: [],
                    from: router,
                  });
                }}
              >
                {cur.name}
              </StoreHeaderButton>
            </a>
          </Link>
        ))}
      </StoreHeaderContainer>
    </>
  );
};

export default StoreHeader;
