import { Box, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { HomepageCollectionsProductCardInterface } from '../../Interface/HomepageCollectionsInterface';
import {
  ProductCardInterface,
  ProductSectionCardInterface,
} from '../../Interface/ProductCardInterface';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { getRegionFromRegionId } from '../../helper/helper';
import { saveEventV3 } from '../../utils/eventTracking';
import { getProductSkuFromSlug } from '../../utils/getProductSkuFromSlug';
import { saveSelectedRecommendations } from '../../utils/recombee';
import { WE_USER_EVENT_COLLECTION_PRODUCT_CLICKED } from '../../utils/we';
import ProductCardG from '../ProductCard/ProductCardG';
import { ProductSectionRow as ProductSectionRowContainer } from './ProductSectionNewStyle';

interface ProductSectionRowPropsI {
  products:
    | Array<ProductSectionCardInterface>
    | Array<HomepageCollectionsProductCardInterface>
    | Array<ProductCardInterface>;
  type: string;
  title: string;
  titleName: string;
  recommendationId?: string | null;
}

const ProductSectionRow2: React.FC<ProductSectionRowPropsI> = ({
  products,
  type,
  title,
  titleName,
  ...props
}) => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();

  return (
    <ProductSectionRowContainer>
      {products.slice(0, 6).map((prod, index) =>
        prod ? (
          <ProductCardG
            key={index}
            product={prod}
            page="home"
            index={index}
            clickBubble={() => {
              if (getProductSkuFromSlug(prod.slug) && props.recommendationId) {
                saveSelectedRecommendations(
                  getProductSkuFromSlug(prod.slug) as string,
                  props.recommendationId
                );
              }
              WE_USER_EVENT_COLLECTION_PRODUCT_CLICKED(
                {
                  collectionName: titleName,
                  pageUrl: window.location.href,
                  productName: prod.title,
                  productSlug: prod.slug,
                  basePrice: prod.price ? prod.price.toString() : '',
                  productPlatform: prod.platform as string,
                  discount:
                    prod && Number(prod.mrp) > 0
                      ? Math.round(
                          ((Number(prod.mrp) - Number(prod.price)) * 100) /
                            Number(prod.mrp)
                        )
                      : 0,
                  productImage: prod.image,
                  productRegion: getRegionFromRegionId(
                    (prod.regionId as string).toString()
                  ),
                },
                cleverTap
              );

              saveEventV3({
                category: 'product_card',
                action: 'click',
                label: titleName,
                properties: 'product',
                value: [prod.slug],
                from: router,
              });
            }}
          />
        ) : (
          <Box key={index} sx={{ ml: '24px' }}>
            <Skeleton
              variant="rectangular"
              height="248px"
              width="178px"
              animation="wave"
              sx={{ borderRadius: '8px' }}
            />
            <Skeleton
              variant="rectangular"
              height="1rem"
              width="70%"
              animation="wave"
              sx={{ borderRadius: '8px', mt: '8px', mb: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              height="1rem"
              width="40%"
              animation="wave"
              sx={{ borderRadius: '8px' }}
            />
          </Box>
        )
      )}
    </ProductSectionRowContainer>
  );
};

export default ProductSectionRow2;
