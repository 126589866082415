import { useContext } from 'react';

import ProductCardsCluster from '../../HomePageV1/components/ProductCardsCluster/ProductCardsCluster';
import ProductsCardRowSkeleton from '../../HomePageV1/components/skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
import { StoreContext } from '../NewStore';
import GiftcardsByPlatform from './GiftcardsByPlatform';
import Memberships from './Memberships';

const Giftcards = () => {
  const { storeData } = useContext(StoreContext);

  return (
    <div>
      <GiftcardsByPlatform />
      <Memberships />

      {!storeData.best_selling_giftcards ||
      !storeData.best_selling_giftcards.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          titleName="best_selling_giftcards"
          title={'Top Selling Giftcards' ?? ''}
          products={storeData.best_selling_giftcards}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} />

      {/* {!storeData.recently_added_giftcards ||
      !storeData.recently_added_giftcards.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          title={'Trending Giftcards' ?? ''}
          products={storeData.recently_added_giftcards}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} /> */}
    </div>
  );
};

export default Giftcards;
