import { Box, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import React, { memo, useCallback } from 'react';
import { HomepageCollectionsProductCardInterface } from '../../Interface/HomepageCollectionsInterface';
import {
  ProductCardInterface,
  ProductNewI,
  ProductSectionCardInterface,
} from '../../Interface/ProductCardInterface';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { getRegionFromRegionId } from '../../helper/helper';
import { saveEventV3 } from '../../utils/eventTracking';
import { getProductSkuFromSlug } from '../../utils/getProductSkuFromSlug';
import { saveSelectedRecommendations } from '../../utils/recombee';
import { WE_USER_EVENT_COLLECTION_PRODUCT_CLICKED } from '../../utils/we';
import ProductCardG from '../ProductCard/ProductCardG';
import { ProductSectionRow as ProductSectionRowContainer } from './ProductSectionNewStyle';

interface ProductSectionRowPropsI {
  products:
    | Array<ProductSectionCardInterface>
    | Array<HomepageCollectionsProductCardInterface>
    | Array<ProductCardInterface>
    | Array<ProductNewI>;
  type: string;
  title: string;
  titleName: string;
  shouldRecommend?: boolean;
  recommendationId?: string | null;
  gridGapNumber?: number;
  showAddToCartBtn?: boolean;
  page?: string;
}

const ProductSectionRow1: React.FC<ProductSectionRowPropsI> = ({
  products,
  type,
  page,
  title,
  shouldRecommend,
  titleName,
  recommendationId,
  gridGapNumber = 16,
  showAddToCartBtn = false,
}) => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();

  const handleProductClick = useCallback(
    (prod, index) => {
      if (getProductSkuFromSlug(prod.slug) && recommendationId) {
        saveSelectedRecommendations(
          getProductSkuFromSlug(prod.slug) as string,
          recommendationId
        );
      }
      WE_USER_EVENT_COLLECTION_PRODUCT_CLICKED(
        {
          collectionName: titleName,
          pageUrl: window.location.href,
          productName: prod.title,
          productSlug: prod.slug,
          basePrice: prod.price ? prod.price.toString() : '',
          productPlatform: prod.platform as string,
          discount:
            prod && Number(prod.mrp) > 0
              ? Math.round(
                  ((Number(prod.mrp) - Number(prod.price)) * 100) /
                    Number(prod.mrp)
                )
              : 0,
          productImage: prod.image,
          productRegion: getRegionFromRegionId(
            (prod.regionId as string).toString()
          ),
        },
        cleverTap
      );

      saveEventV3({
        category: 'product_card',
        action: 'click',
        label: titleName,
        properties: 'product',
        value: [prod.slug],
        from: router,
      });
    },
    [cleverTap, recommendationId, router, titleName]
  );

  const renderProductCard = (prod: any, index: number) => (
    <>
      <ProductCardG
        key={index}
        product={prod}
        page="home"
        index={index}
        showAddToCartBtn={showAddToCartBtn}
        recommendationId={recommendationId}
        scenario={titleName}
        clickBubble={() => handleProductClick(prod, index)}
        widthType="fixed"
      />
    </>
  );

  const renderSkeleton = (index: number) => (
    <Box key={index} sx={{ ml: '24px' }}>
      <Skeleton
        variant="rectangular"
        height="248px"
        width="178px"
        animation="wave"
        sx={{ borderRadius: '8px' }}
      />
      <Skeleton
        variant="rectangular"
        height="1rem"
        width="70%"
        animation="wave"
        sx={{ borderRadius: '8px', mt: '8px', mb: '4px' }}
      />
      <Skeleton
        variant="rectangular"
        height="1rem"
        width="40%"
        animation="wave"
        sx={{ borderRadius: '8px' }}
      />
    </Box>
  );

  return (
    <ProductSectionRowContainer
      style={{ flexWrap: router.pathname === '/' ? 'wrap' : 'nowrap' }}
    >
      {products.map((prod, index) =>
        prod ? renderProductCard(prod, index) : renderSkeleton(index)
      )}
    </ProductSectionRowContainer>
  );
};

export default memo(ProductSectionRow1);
