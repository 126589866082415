import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import Link from 'next/dist/client/link';
import { useAnalytics } from '../../../contexts/AnalyticsProvider';
import { useLocale } from '../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../utils/eventTracking';
import { WE_USER_EVENT_MEMBERSHIP_CLICKED } from '../../../utils/we';
import { StoreContext } from '../NewStore';
import {
  MembershipItem,
  MembershipsContainer,
  SeeThroughTag,
  StoreSection,
} from '../NewStoreStyles';

const Memberships: React.FC = () => {
  const router = useRouter();

  const { messages } = useLocale();
  const { explore_memberships_msg, membership_msg } = messages || {};

  const { storeData, loading } = useContext(StoreContext);
  const { cleverTap } = useAnalytics();

  return (
    <>
      {!loading && storeData && storeData.memberships.length > 0 && (
        <StoreSection>
          <h3>{explore_memberships_msg}</h3>
          <MembershipsContainer>
            {storeData.memberships.map((cur, i) => (
              <Link passHref href={cur.link} key={i}>
                <MembershipItem
                  onClick={() => {
                    WE_USER_EVENT_MEMBERSHIP_CLICKED(
                      {
                        link: cur.link,
                        name: cur.title,
                        pageUrl: window.location.href,
                        imageUrl: cur.imageUrl,
                      },
                      cleverTap
                    );
                    saveEventV3({
                      category: 'collection_card',
                      action: 'click',
                      label: 'explore_membership',
                      properties: (router.query.group as string) ?? '',
                      value: [cur.title],
                      from: router,
                    });
                  }}
                  bg={cur.metadata.bg}
                >
                  <Image
                    src={cur.imageUrl}
                    width={cur.metadata.size.width}
                    height={cur.metadata.size.height}
                    alt={cur.imageUrl}
                  />
                  <SeeThroughTag>{membership_msg}</SeeThroughTag>
                </MembershipItem>
              </Link>
            ))}
          </MembershipsContainer>
        </StoreSection>
      )}
    </>
  );
};

export default Memberships;
