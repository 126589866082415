import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';
import Slider from 'react-slick';
import { StoreContext } from '../NewStore';

import Link from 'next/dist/client/link';
import { WIDTH } from '../../../constants/screenResolution';
import { useLocale } from '../../../contexts/LocalizationContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import {
  SampleNextArrow,
  SamplePreviousArrow,
} from '../../ProductSection/ProductSectionG';
import {
  ImageItemTile,
  ItemsSliderContainer,
  MobileSlider,
  SeeThroughTag,
  StoreSection,
} from '../NewStoreStyles';

const GamepointsGames = () => {
  const { messages } = useLocale();
  const { explore_msg } = messages || {};

  const { width } = useWindowDimensions();
  const { storeData, loading } = useContext(StoreContext);
  const router = useRouter();

  const settings = useMemo(
    () => ({
      adaptiveHeight: false,
      infinite: true,
      speed: 500,
      // slidesToShow: 6,
      slidesToScroll: 2,
      variableWidth: true,
      swipeToSlide: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePreviousArrow />,

      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePreviousArrow />,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            // slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            arrows: false,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePreviousArrow />,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 1439,
          settings: {
            // slidesToShow: 6,
            slidesToScroll: 5,
            infinite: true,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePreviousArrow />,
            swipeToSlide: true,
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      {!loading &&
        storeData &&
        storeData.explore_gamepoints_games.length > 0 && (
          <StoreSection>
            <h3>{explore_msg} Gamepoints - Games</h3>
            <ItemsSliderContainer>
              {width &&
              width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
                <Slider {...settings}>
                  {storeData.explore_gamepoints_games.map((cur, i) => (
                    <div key={i}>
                      <Link passHref href={cur.link}>
                        <a style={{ padding: 0 }}>
                          <ImageItemTile
                            onClick={() => {
                              saveEventV3({
                                category: 'collection_card',
                                action: 'click',
                                label: 'explore_gamepoints_game',
                                properties:
                                  (router.query.group as string) ?? '',
                                value: [cur.title],
                                from: router,
                              });
                            }}
                          >
                            <Image
                              src={cur.imageUrl}
                              alt={cur.title}
                              layout="fill"
                            />
                            <SeeThroughTag>Game points</SeeThroughTag>
                          </ImageItemTile>
                        </a>
                      </Link>
                    </div>
                  ))}
                </Slider>
              ) : (
                <MobileSlider>
                  {storeData.explore_gamepoints_games.map((cur, i) => (
                    <div key={i}>
                      <Link passHref href={cur.link}>
                        <a>
                          <ImageItemTile
                            onClick={() => {
                              saveEventV3({
                                category: 'collection_card',
                                action: 'click',
                                label: 'explore_gamepoints_game',
                                properties:
                                  (router.query.group as string) ?? '',
                                value: [cur.title],
                                from: router,
                              });
                            }}
                          >
                            <Image
                              src={cur.imageUrl}
                              alt={cur.title}
                              layout="fill"
                            />
                            <SeeThroughTag>Game points</SeeThroughTag>
                          </ImageItemTile>
                        </a>
                      </Link>
                    </div>
                  ))}
                </MobileSlider>
              )}
            </ItemsSliderContainer>
          </StoreSection>
        )}
    </>
  );
};

export default GamepointsGames;
