import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext, useMemo } from 'react';
import Slider from 'react-slick';

import Link from 'next/dist/client/link';
import { WIDTH } from '../../../constants/screenResolution';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import {
  SampleNextArrow,
  SamplePreviousArrow,
} from '../../ProductSection/ProductSectionG';
import { StoreContext } from '../NewStore';
import {
  ImageItemTile,
  ItemsSliderContainer,
  MobileSlider,
  SeeThroughTag,
  StoreSection,
} from '../NewStoreStyles';
import { useLocale } from '../../../contexts/LocalizationContext';

const GamepointsMobile: React.FC = () => {
  const { width } = useWindowDimensions();
  const { storeData, loading } = useContext(StoreContext);
  const router = useRouter();
  let { messages } = useLocale();
  const { explore_gamepoings_mobile_msg,game_points_msg } = messages || {};

  const settings = useMemo(
    () => ({
      adaptiveHeight: false,
      infinite: true,
      speed: 500,
      // slidesToShow: 6,
      slidesToScroll: 2,
      variableWidth: true,
      swipeToSlide: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePreviousArrow />,

      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePreviousArrow />,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            // slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            arrows: false,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePreviousArrow />,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 1439,
          settings: {
            // slidesToShow: 6,
            slidesToScroll: 5,
            infinite: true,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePreviousArrow />,
            swipeToSlide: true,
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      {!loading &&
        storeData &&
        storeData.explore_gamepoints_mobile_games.length > 0 && (
          <StoreSection>
            <h3>{explore_gamepoings_mobile_msg}</h3>
            <ItemsSliderContainer>
              {width &&
              width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
                <Slider {...settings}>
                  {storeData.explore_gamepoints_mobile_games.map((cur, i) => (
                    <div key={i}>
                      <ImageItemTile>
                        <Link passHref href={cur.link}>
                          <a>
                            <Image
                              src={cur.imageUrl}
                              onClick={() => {
                                saveEventV3({
                                  category: 'collection_card',
                                  action: 'click',
                                  label: 'explore_gamepoints_mobile',
                                  properties:
                                    (router.query.group as string) ?? '',
                                  value: [cur.title],
                                  from: router,
                                });
                              }}
                              alt={cur.title}
                              layout="fill"
                            />
                          </a>
                        </Link>
                        <SeeThroughTag>{game_points_msg}</SeeThroughTag>
                      </ImageItemTile>
                    </div>
                  ))}
                </Slider>
              ) : (
                <MobileSlider>
                  {storeData.explore_gamepoints_mobile_games.map((cur, i) => (
                    <div key={i}>
                      <ImageItemTile>
                        <Link passHref href={cur.link}>
                          <a>
                            <Image
                              src={cur.imageUrl}
                              onClick={() => {
                                saveEventV3({
                                  category: 'collection_card',
                                  action: 'click',
                                  label: 'explore_gamepoints_mobile',
                                  properties:
                                    (router.query.group as string) ?? '',
                                  value: [cur.title],
                                  from: router,
                                });
                              }}
                              alt={cur.title}
                              layout="fill"
                            />
                          </a>
                        </Link>
                        <SeeThroughTag>Game points</SeeThroughTag>
                      </ImageItemTile>
                    </div>
                  ))}
                </MobileSlider>
              )}
            </ItemsSliderContainer>
          </StoreSection>
        )}
    </>
  );
};

export default GamepointsMobile;
