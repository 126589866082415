import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const NewStoreContainer = styled.div`
  flex: 1;
  max-width: calc(100% - 286px - 24px);
  @media (max-width: ${WIDTH.laptopMin}) {
    max-width: 100%;
    margin-bottom: 24px;
  }
`;

export const StoreHeaderContainer = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
  background: ${(props) => props.theme.palette.background.bg1};
  box-shadow: ${(props) => props.theme.shadows.s1};
  margin-bottom: 16px;

  @media (max-width: ${WIDTH.mobileMax}) {
    border-radius: 0;
    padding: 12px 16px;
    max-width: 100vh;
    overflow: auto;
    margin-bottom: 16px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StoreHeaderButton = styled.div<{ selected?: boolean }>`
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) =>
    props.selected
      ? props.theme.palette.primary.light
      : props.theme.palette.background.bg3};
  padding: 13px;
  display: grid;
  place-content: center;
  font-size: 18px;
  line-height: 22px;
  font-family: Onest-Bold;
  transition: 0.3s;
  color: ${(props) =>
    props.selected
      ? props.theme.palette.text.primary
      : props.theme.palette.text.t1};

  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 16px;
    line-height: 20px;
    padding: 12px 20px;
    white-space: nowrap;
  }
`;

export const StoreSection = styled.section`
  & > h3 {
    font: 1.625rem/1.875rem Onest-SemiBold;
    margin-bottom: 16px;

    color: ${(props) => props.theme.palette.text.t1};
  }
  margin-bottom: 24px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding-bottom: 24px;
    margin-bottom: 0;
    & > h3 {
      font: 1.25rem/1.5rem Onest-SemiBold;
      margin-bottom: 12px;
      margin-left: 16px;
    }
  }
`;

export const ItemsContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg1};
  padding: 16px;
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(211px, 1fr));
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.shadows.s1};

  flex-wrap: wrap;

  @media (max-width: ${WIDTH.mobileMax}) {
    background: transparent;
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
    gap: 12px;
    box-shadow: none;
    padding: 0 16px;
  }
`;

export const CategorySectionMobile = styled.div`
  display: grid;
  grid-template-rows: 88px 88px;
  grid-template-columns: repeat(7, 88px);
  grid-gap: 12px;
  // gap: 12px;
  padding: 0 16px;
  overflow: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  & > div {
    min-height: 88px;
    min-width: 88px;
  }
`;

export const ItemsSliderContainer = styled.div`
  padding: 0 0 16px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.s1};
  background: ${(props) => props.theme.palette.background.bg1};
  max-width: 1151px;
  & .slick-slide {
    padding-top: 16px;
  }
  & a {
    padding-left: 16px;
    display: block;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    max-width: none;
    & a {
      padding-left: 0px;
      display: block;
    }
  }
`;

export const MobileSlider = styled.div`
  display: flex;
  // grid-template-columns: repeat(3, 202px);
  // grid-gap: 12px;
  gap: 12px;
  overflow: auto;
  padding: 0 16px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PriceTile = styled.div`
  height: 144px;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.text.t1};
  min-height: 144px;
  min-width: 211px;
  display: grid;
  place-items: center;
  place-content: center;
  background: ${(props) => props.theme.palette.background.bg4};
  font-family: Onest-Bold;
  box-shadow: ${(props) => props.theme.shadows.s2};
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s;
  text-align: center;
  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    min-height: 59px;
    min-width: 107px;
    box-shadow: ${(props) => props.theme.shadows.s1};
    height: 59px;
    border-radius: 12px;
    font-size: 15px;
    line-height: 20px;
    &:hover {
      transform: translateY(0px);
    }
  }
`;

export const ImageItemTile = styled.div`
  min-width: 300px;
  height: 190px;
  min-height: 190px;
  border-radius: 12px;
  background: ${(props) => props.theme.palette.background.bg3};
  margin-left: 16px;
  position: relative;
  cursor: pointer;
  // margin-top: 16px;
  overflow: hidden;
  transition: 0.3s;
  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    width: 202px;
    height: 128px;
    min-height: 128px;
    min-width: 202px;
    margin-left: 0;
    &:hover {
      transform: none;
    }
  }
`;

export const SeeThroughTag = styled.div`
  background: ${(props) => props.theme.palette.background.bg4};
  opacity: 0.9;
  border-radius: 8px;
  padding: 8px 12px;
  width: fit-content;
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 14px;
    line-height: 18px;
    padding: 6px 12px;
    bottom: 8px;
    right: 8px;
  }
`;

export const MembershipsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  @media (max-width: ${WIDTH.mobileMax}) {
    display: flex;
    gap: 12px;
    background: transparent;
    padding: 0 16px;
    overflow: auto;
    border-radius: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const MembershipItem = styled.a<{ bg: string }>`
  box-shadow: ${(props) => props.theme.shadows.s3};
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 190px;
  background: ${(props) => props.bg};
  display: grid;
  place-content: center;
  cursor: pointer;
  & > div:first-of-type {
    transition: 0.3s;
  }
  &:hover {
    & > div:first-of-type {
      transform: scale(1.05);
    }
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    min-width: 90%;
    height: 128px;
    border-radius: 12px;
    & > div:first-of-type {
      transform: scale(0.7);
    }
    &:hover {
      & > div:first-of-type {
        transform: scale(0.7);
      }
    }
  }
`;

export const CuratorsPickContainer = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.background.bg1};
  box-shadow: ${(props) => props.theme.shadows.s1};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  @media (max-width: ${WIDTH.mobileMax}) {
    display: flex;
    gap: 12px;
    background: transparent;
    padding: 0 16px;
    overflow: auto;
    border-radius: 0;
    &::-webkit-scrollbar {
      display: none;
    }
    & > div {
      max-width: 90%;
    }
  }
`;

export const MobileCardSlider = styled.div`
  padding: 0 16px;
  display: flex;
  gap: 12px;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow: auto;
`;

export const MobileProductCardSlider = styled.div<{ columns: number }>`
  padding: 0 16px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: ${(props) => `repeat(${props.columns},100%)`};
  &::-webkit-scrollbar {
    display: none;
  }
  overflow: auto;
`;

export const SkeletonSliderContainer = styled.div<{ columns?: number }>`
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.s1};
  background: ${(props) => props.theme.palette.background.bg1};
  max-width: 1151px;
  display: flex;
  overflow: hidden;
  gap: 16px;
  margin-top: 24px;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 0 16px;
    display: grid;
    background: transparent;
    grid-gap: 12px;
    grid-template-columns: ${(props) => `repeat(${props.columns},100%)`};
    &::-webkit-scrollbar {
      display: none;
    }
    overflow: auto;
  }
`;
