import React, { useContext } from 'react';

import { useLocale } from '../../../contexts/LocalizationContext';
import ProductCardsCluster from '../../HomePageV1/components/ProductCardsCluster/ProductCardsCluster';
import ProductsCardRowSkeleton from '../../HomePageV1/components/skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
import { StoreContext } from '../NewStore';
import CuratorsPick from './CuratorsPick';
import GamepointsGames from './GamepointsGames';
import GamepointsMobile from './GamepointsMobile';
import GamesByGenre from './GamesByGenre';
import GamesByPlatform from './GamesByPlatform';
import GamesUnderPrice from './GamesUnderPrice';
import GiftcardsByPlatform from './GiftcardsByPlatform';
import Memberships from './Memberships';

const Explore: React.FC = () => {
  const { storeData } = useContext(StoreContext);

  const { messages } = useLocale();
  const { latest_releases_msg, premium_deals_msg, best_selling_games_msg } =
    messages || {};

  return (
    <>
      {!storeData.premium_deals || !storeData.premium_deals.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          titleName="premium_deals"
          title={premium_deals_msg ?? ''}
          products={storeData.premium_deals}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} />
      <GamesByPlatform />
      <GamesUnderPrice />
      <GiftcardsByPlatform />
      <GamesByGenre />
      <Memberships />
      {!storeData.latest_released_games ||
      !storeData.latest_released_games.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          titleName="latest_released_games"
          title={latest_releases_msg ?? ''}
          products={storeData.latest_released_games}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} />

      <GamepointsGames />
      <GamepointsMobile />

      {!storeData.best_selling_games || !storeData.best_selling_games.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          titleName="best_selling_games"
          title={best_selling_games_msg ?? ''}
          products={storeData.best_selling_games}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} />

      <CuratorsPick />
    </>
  );
};

export default Explore;
