import { useRouter } from 'next/router';
import Slider from 'react-slick';

import Link from 'next/dist/client/link';
import Image from 'next/image';
import { WIDTH } from '../../../constants/screenResolution';
import { useAnalytics } from '../../../contexts/AnalyticsProvider';
import { useLocale } from '../../../contexts/LocalizationContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import { WE_USER_EVENT_WE_PLATFORM_CLICKED } from '../../../utils/we';
import { sliderSettings } from '../NewStore';
import {
  ImageItemTile,
  ItemsSliderContainer,
  MobileSlider,
  SeeThroughTag,
  StoreSection,
} from '../NewStoreStyles';
import { gamesByPlatform } from '../data';

const GamesByPlatform = () => {
  const { width } = useWindowDimensions();
  const router = useRouter();
  const { cleverTap } = useAnalytics();
  let { messages } = useLocale();
  const { explore_games_by_platform_msg, games_msg } = messages || {};

  return (
    <StoreSection>
      <h3>
        {width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
          ? explore_games_by_platform_msg
          : 'Games by Platform'}
      </h3>
      <ItemsSliderContainer>
        {width && width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
          <Slider {...sliderSettings}>
            {gamesByPlatform.map((cur, i) => (
              <div key={i}>
                <Link passHref href={cur.url}>
                  <a>
                    <ImageItemTile
                      onClick={() => {
                        WE_USER_EVENT_WE_PLATFORM_CLICKED(
                          {
                            link: cur.url,
                            pageUrl: window.location.href,
                            platformName: cur.title,
                            image: cur.imageUrl,
                          },
                          cleverTap
                        );
                        saveEventV3({
                          category: 'collection_card',
                          action: 'click',
                          label: 'games_by_platform',
                          properties: (router.query.group as string) ?? '',
                          value: [cur.title],
                          from: router,
                        });
                      }}
                    >
                      <Image src={cur.imageUrl} alt={cur.title} layout="fill" />
                      <SeeThroughTag>{games_msg}</SeeThroughTag>
                    </ImageItemTile>
                  </a>
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <MobileSlider>
            {gamesByPlatform.map((cur, i) => (
              <div key={i}>
                <Link passHref href={cur.url}>
                  <a>
                    <ImageItemTile
                      onClick={() => {
                        WE_USER_EVENT_WE_PLATFORM_CLICKED(
                          {
                            link: cur.url,
                            pageUrl: window.location.href,
                            platformName: cur.title,
                            image: cur.imageUrl,
                          },
                          cleverTap
                        );
                        saveEventV3({
                          category: 'collection_card',
                          action: 'click',
                          label: 'games_by_platform',
                          properties: (router.query.group as string) ?? '',
                          value: [cur.title],
                          from: router,
                        });
                      }}
                    >
                      <Image src={cur.imageUrl} alt={cur.title} layout="fill" />
                      <SeeThroughTag>Games</SeeThroughTag>
                    </ImageItemTile>
                  </a>
                </Link>
              </div>
            ))}
          </MobileSlider>
        )}
      </ItemsSliderContainer>
    </StoreSection>
  );
};

export default GamesByPlatform;
