import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import useSWR from 'swr';
import {
  AxiosInstanceCreatorInterface,
  CustomSwrRequestConfig,
} from '../Interface/RequestInterface';

const createAxiosInstance = (x: AxiosInstanceCreatorInterface) => {
  let axiosReqConfig: AxiosRequestConfig = {
    headers: {
      ...x.axiosHeaders,
    },
    withCredentials: true,
    timeout: 10000,
    ...x.axiosConfig,
  };

  if (x.params) axiosReqConfig.params = x.params;
  const instance = axios.create({
    method: x.method,
    ...axiosReqConfig,
  });

  return instance;
};

/**
 * To be used for all requests except for get-SWR requests
 * @param param0
 * @returns
 */
// export const useAxiosRequestHook = <T,>({
//   url,
//   method,
//   payload,
//   initialData = null,
//   axiosConfig = {},
//   axiosHeaders = {},
//   params = {},
//   onSuccess,
//   onError,
// }: CustomAxiosInterceptor<T>): {
//   data: T | null;
//   error: string;
//   loaded: boolean;
//   requestCallFunction: () => void;
// } => {
//   const [data, setData] = useState<T | null>(initialData);
//   const [error, setError] = useState<string>('');
//   const [loaded, setLoaded] = useState<boolean>(false);

//   const dispatch = useAppDispatch();

//   const requestCallFunction = useCallback(async () => {
//     try {
//       const instance = createAxiosInstance({
//         axiosConfig,
//         axiosHeaders,
//         params,
//         method,
//       });

//       const response = await instance.request({ url, data: payload });

//       if (onSuccess) {
//         onSuccess(response.data);
//       } else {
//         setData(response.data);
//       }
//     } catch (error: any) {
//       if (error && error.response && error.response.status === 401) {
//         dispatch(logoutUserServer());
//       }
//       if (onError) {
//         onError(error);
//       } else {
//         setError(error.message);
//       }
//     } finally {
//       setLoaded(true);
//     }
//   }, [method, payload, url]);

//   return { data, error, loaded, requestCallFunction };
// };

export const useSwrRequestHook = <T,>({
  url,
  shouldFetch,
  initialData = null,
  initialError = '',
  initialLoadedState = false,
  swrConfig,
  axiosConfig = {},
  axiosHeaders = {},
  params = {},
}: CustomSwrRequestConfig<T>): {
  data: T | null;
  error: string;
  loaded: boolean;
} => {
  const [data, setData] = useState<T | null>(initialData);
  const [error, setError] = useState<string>(initialError);
  const [loaded, setLoaded] = useState<boolean>(initialLoadedState);

  let axiosReqConfig: AxiosRequestConfig = {
    headers: {
      ...axiosHeaders,
    },
    timeout: 10000,
    ...axiosConfig,
  };

  if (params) axiosReqConfig.params = params;

  const instance = createAxiosInstance({
    axiosConfig,
    axiosHeaders,
    params,
    method: 'GET',
  });

  const fetcher = (url: string) =>
    instance
      .request({ url })
      .then((res) => setData(res.data))
      .catch((err) => setError(err.message))
      .finally(() => setLoaded(true));

  useSWR(shouldFetch ? url : null, fetcher, {
    ...swrConfig,
  });
  return { data, error, loaded };
};
