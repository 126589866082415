import dynamic from 'next/dynamic';

import { useRouter } from 'next/router';
import { BannerImageI } from '../../../Interface/HomepageCollectionsInterface';
import { apiConstants } from '../../../constants/apiConstants';
import { useAnalytics } from '../../../contexts/AnalyticsProvider';
import { useSwrRequestHook } from '../../../hooks/useRequest';
import { saveEventV3 } from '../../../utils/eventTracking';
import { WE_USER_EVENT_CURATORS_PICK_CLICKED } from '../../../utils/we';
import {
  NRBImage,
  NRBItem,
} from '../../NewReleaseBanner/NewReleaseBannerStyle';
import { CuratorsPickContainer, StoreSection } from '../NewStoreStyles';
import { useLocale } from '../../../contexts/LocalizationContext';

const Link = dynamic(() => import('next/link'), {
  ssr: false,
});

const CuratorsPick = () => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();
  let { messages } = useLocale();
  const { curators_picks_msg } = messages || {};

  const y: {
    status: number;
    data: {
      bannerImages: Array<BannerImageI>;
    };
    count?: number;
  } = { status: 200, data: { bannerImages: [] } };

  const homePageBannerRequestFields = {
    shouldFetch: true,
    initialData: y,
    swrConfig: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  };

  const { data: newReleaseBanner, loaded } = useSwrRequestHook({
    url: apiConstants.server + '/public/banners?type=2up',
    ...homePageBannerRequestFields,
  });

  return (
    <>
      {loaded &&
        newReleaseBanner &&
        newReleaseBanner.data.bannerImages.length > 0 && (
          <StoreSection>
            <h3>{curators_picks_msg}</h3>
            <CuratorsPickContainer>
              {newReleaseBanner?.data?.bannerImages?.map(
                (item: BannerImageI, index: number) => {
                  return (
                    <Link
                      key={index}
                      passHref
                      href={item.link}
                      prefetch={false}
                    >
                      <NRBItem
                        onClick={() => {
                          WE_USER_EVENT_CURATORS_PICK_CLICKED(
                            {
                              pageUrl: window.location.href,
                              productImage: item.imageUrl,
                              productName: item.title,
                              productSlug: item.link,
                            },
                            cleverTap
                          );
                          saveEventV3({
                            category: 'banner',
                            action: 'click',
                            label: 'curators_pick',
                            properties: '2up_cards',
                            value: [item.title],
                            from: router,
                          });
                        }}
                        id={
                          index ===
                          newReleaseBanner?.data?.bannerImages?.length - 1
                            ? 'ac'
                            : ''
                        }
                      >
                        <NRBImage src={item.imageUrl} alt={item.title} />
                      </NRBItem>
                    </Link>
                  );
                }
              )}
            </CuratorsPickContainer>
          </StoreSection>
        )}
    </>
  );
};

export default CuratorsPick;
