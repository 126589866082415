import { useContext } from 'react';

import { useLocale } from '../../../contexts/LocalizationContext';
import ProductCardsCluster from '../../HomePageV1/components/ProductCardsCluster/ProductCardsCluster';
import ProductsCardRowSkeleton from '../../HomePageV1/components/skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
import { StoreContext } from '../NewStore';
import CuratorsPick from './CuratorsPick';
import GamesByGenre from './GamesByGenre';
import GamesByPlatform from './GamesByPlatform';
import GamesUnderPrice from './GamesUnderPrice';

const Games = () => {
  const { storeData } = useContext(StoreContext);

  const { messages } = useLocale();
  const { top_selling_games_msg, recently_added_games_msg } = messages || {};

  return (
    <div>
      {!storeData.best_selling_games || !storeData.best_selling_games.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          titleName="best_selling_games"
          title={top_selling_games_msg ?? ''}
          products={storeData.best_selling_games}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} />

      <GamesByGenre />
      <GamesByPlatform />
      {!storeData.recently_added_games ||
      !storeData.recently_added_games.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          titleName="recently_added_games"
          title={recently_added_games_msg ?? ''}
          products={storeData.recently_added_games}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} />

      <GamesUnderPrice />
      <CuratorsPick />
    </div>
  );
};

export default Games;
