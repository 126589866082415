import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { BannerI } from '../../Interface/BannersInterface';
import { HomepageCollectionsProductCardInterface } from '../../Interface/HomepageCollectionsInterface';
import { apiConstants } from '../../constants/apiConstants';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import {
  SampleNextArrow,
  SamplePreviousArrow,
} from '../ProductSection/ProductSectionG';
import WebLister from '../WebLister/WebLister';
import { NewStoreContainer } from './NewStoreStyles';
import Explore from './components/Explore';
import GamePoints from './components/GamePoints';
import Games from './components/Games';
import Giftcards from './components/Giftcards';
import StoreHeader from './components/StoreHeader';

export const sliderSettings = {
  adaptiveHeight: false,
  infinite: true,
  speed: 500,
  // slidesToShow: 6,
  slidesToScroll: 2,
  variableWidth: true,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePreviousArrow />,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePreviousArrow />,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        // slidesToShow: 5,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePreviousArrow />,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 1439,
      settings: {
        // slidesToShow: 6,
        slidesToScroll: 5,
        infinite: true,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePreviousArrow />,
        swipeToSlide: true,
      },
    },
  ],
};

interface StoreDataI {
  best_selling_gamepoints: HomepageCollectionsProductCardInterface[];
  best_selling_games: HomepageCollectionsProductCardInterface[];
  best_selling_giftcards: HomepageCollectionsProductCardInterface[];
  latest_released_games: HomepageCollectionsProductCardInterface[];
  trending_games: HomepageCollectionsProductCardInterface[];
  trending_products: HomepageCollectionsProductCardInterface[];
  premium_deals: HomepageCollectionsProductCardInterface[];
  recently_added_games: HomepageCollectionsProductCardInterface[];
  recently_added_gamepoints: HomepageCollectionsProductCardInterface[];
  recently_added_giftcards: HomepageCollectionsProductCardInterface[];
  recently_added_products: HomepageCollectionsProductCardInterface[];
  memberships: BannerI[];
  explore_gamepoints_games: BannerI[];
  explore_gamepoints_mobile_games: BannerI[];
}

const initialStoreData: StoreDataI = {
  best_selling_gamepoints: [],
  best_selling_games: [],
  best_selling_giftcards: [],
  latest_released_games: [],
  trending_games: [],
  trending_products: [],
  premium_deals: [],
  recently_added_games: [],
  recently_added_gamepoints: [],
  recently_added_giftcards: [],
  recently_added_products: [],
  memberships: [],
  explore_gamepoints_games: [],
  explore_gamepoints_mobile_games: [],
};

export const StoreContext = createContext({
  storeData: initialStoreData,
  loading: true,
});

const NewStore = () => {
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const router = useRouter();
  const [selectedType, setSelectedType] = useState<string>('all');
  const [storeData, setStoreData] = useState<StoreDataI>(initialStoreData);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (router.query.group) {
      setSelectedType(router.query.group as string);
    } else {
      setSelectedType('all');
    }
  }, [router.query.group]);

  useEffect(() => {
    if (
      selectedType === 'explore' ||
      selectedType === 'games' ||
      selectedType === 'giftcards' ||
      selectedType === 'gamepoints'
    ) {
      axios
        .get(apiConstants.server + '/public/collections/storepage')
        .then(({ data }) => {
          setStoreData({
            best_selling_gamepoints: data.data.homepage_best_selling_gamepoints,
            best_selling_games: data.data.homepage_best_selling_games,
            best_selling_giftcards: data.data.homepage_best_selling_giftcards,
            latest_released_games: data.data.homepage_latest_released_games,
            trending_games: data.data.storepage_trending_games,
            trending_products: data.data.storepage_trending_products,
            premium_deals: data.data.homepage_premium_deals,
            recently_added_games: data.data.homepage_recently_added_games,
            recently_added_gamepoints:
              data.data.storepage_recently_added_gamepoints,
            recently_added_giftcards:
              data.data.storepage_recently_added_giftcards,
            recently_added_products:
              data.data.storepage_recently_added_products,
            memberships: data.data.memberships,
            explore_gamepoints_games: data.data.explore_gamepoints_games,
            explore_gamepoints_mobile_games:
              data.data.explore_gamepoints_mobile_games,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          dispatchSnackbar({
            type: 'failure',
            payload:
              err?.response?.data?.message ||
              'Some error occurred while fetching store data. Please try after sometime.',
          });
        });
    }
  }, [dispatchSnackbar, selectedType]);

  return (
    <StoreContext.Provider value={{ storeData, loading }}>
      <NewStoreContainer>
        <StoreHeader selectedType={selectedType} />
        {selectedType === 'all' && <WebLister />}
        {selectedType === 'explore' && <Explore />}
        {selectedType === 'games' && <Games />}
        {selectedType === 'giftcards' && <Giftcards />}
        {selectedType === 'gamepoints' && <GamePoints />}
        {/* {selectedType === 4 && <DLCs />} */}
      </NewStoreContainer>
    </StoreContext.Provider>
  );
};

export default NewStore;
