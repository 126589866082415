import React from 'react';

import { WIDTH } from '../../../constants/screenResolution';
import { useLocale } from '../../../contexts/LocalizationContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import { generateCategoriesList } from '../../CategorySection/CategorySection';
import { CategoriesSection } from '../../CategorySection/CategorySectionStyle';
import CategoryTile from '../../CategoryTile/CategoryTile';
import { CategorySectionMobile, StoreSection } from '../NewStoreStyles';

const GamesByGenre: React.FC = () => {
  const { width } = useWindowDimensions();

  const { messages } = useLocale();
  const { games_by_genre_msg } = messages || {};
  const CategoriesList = generateCategoriesList(messages)

  return (
    <StoreSection style={{ paddingBottom: 0 }}>
      <h3 style={{ marginBottom: 0 }}>{games_by_genre_msg}</h3>
      {width && width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
        <CategoriesSection id="categories-slider">
          {CategoriesList.slice(0, -2).map((category, index) => (
            <CategoryTile location="store" key={index} category={category} />
          ))}
        </CategoriesSection>
      ) : (
        <CategorySectionMobile
          style={{ paddingTop: '12px', paddingBottom: '24px' }}
        >
          {CategoriesList.map((category, index) => (
            <CategoryTile location="store" key={index} category={category} />
          ))}
        </CategorySectionMobile>
      )}
    </StoreSection>
  );
};

export default GamesByGenre;
