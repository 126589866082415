import { useContext } from 'react';

import ProductCardsCluster from '../../HomePageV1/components/ProductCardsCluster/ProductCardsCluster';
import ProductsCardRowSkeleton from '../../HomePageV1/components/skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
import { StoreContext } from '../NewStore';
import GamepointsGames from './GamepointsGames';
import GamepointsMobile from './GamepointsMobile';

const GamePoints = () => {
  const { storeData } = useContext(StoreContext);

  return (
    <div>
      <GamepointsGames />
      <GamepointsMobile />
      {!storeData.best_selling_gamepoints ||
      !storeData.best_selling_gamepoints.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          title={'Top Selling Gamepoints' ?? ''}
          titleName="best_selling_gamepoints"
          products={storeData.best_selling_gamepoints}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} />

      {/* {!storeData.recently_added_gamepoints ||
      !storeData.recently_added_gamepoints.length ? (
        <ProductsCardRowSkeleton />
      ) : (
        <ProductCardsCluster
          title={'Trending Gamepoints' ?? ''}
          products={storeData.recently_added_gamepoints}
          arrowPosition="header"
          padding="0 0 0 0"
        />
      )}
      <div style={{ height: '24px', width: '100%' }} /> */}
    </div>
  );
};

export default GamePoints;
