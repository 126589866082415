export const gamesByPlatform = [
  {
    title: 'psn-games',
    imageUrl: '/images/games-platforms/psn.webp',
    url: '/store/psn-games',
  },
  {
    title: 'steam-games',
    imageUrl: '/images/games-platforms/steam.webp',
    url: '/store/steam-games',
  },
  {
    title: 'xbox-games',
    imageUrl: '/images/games-platforms/xbox.webp',
    url: '/store/xbox-games',
  },
  {
    title: 'nintendo-games',
    imageUrl: '/images/games-platforms/nintendo.webp',
    url: '/store/nintendo-games',
  },
  {
    title: 'ea-play-games',
    imageUrl: '/images/games-platforms/ea-play.webp',
    url: '/store/ea-play-games',
  },
  {
    title: 'gog-games',
    imageUrl: '/images/games-platforms/gog.webp',
    url: '/store/gog-games',
  },
  {
    title: 'ubisoft-games',
    imageUrl: '/images/games-platforms/ubisoft.webp',
    url: '/store/ubisoft-connect-games',
  },
];

export const giftcardsByPlatform = [
  {
    title: 'roblox-gift-cards',
    imageUrl: '/images/giftcards-platform/roblox.webp',
    url: '/store?q=roblox&productType[]=giftcard&page=1',
  },
  {
    title: 'nintendo-gift-cards',
    imageUrl: '/images/giftcards-platform/nintendo.webp',
    url: '/store?productType[]=giftcard&page=1&platform[]=Nintendo',
  },
  {
    title: 'steam-gift-cards',
    imageUrl: '/images/giftcards-platform/steam.webp',
    url: '/store?productType[]=giftcard&page=1&platform[]=Steam',
  },
  {
    title: 'psn-gift-cards',
    imageUrl: '/images/giftcards-platform/psn.webp',
    url: '/store?productType[]=giftcard&page=1&platform[]=PSN',
  },
  {
    title: 'xbox-gift-cards',
    imageUrl: '/images/giftcards-platform/xbox.webp',
    url: '/store?productType[]=giftcard&page=1&platform[]=Xbox+Live',
  },
];
