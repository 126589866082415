import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';

import { createQueryFilter, getRegionFromRegionId } from '../../helper/helper';

import MemodFilterSidebar from '../../components/FilterSidebar/FilterSidebar';

import {
  BodyContainer,
  ListerFlex,
  ListerWrapper,
  WebSidebarContainer,
} from '../../styles/pageStyles/productsStyle';

import axios from 'axios';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { FilterResponse } from '../../Interface/FiltersInterface';
import { GtagItemInterface } from '../../Interface/GtagInterface';
import FilterDrawer from '../../components/FilterDrawer/FilterDrawer';
import { SortOptions } from '../../components/FilterDropdown/FilterOptionsConst';
import Footer from '../../components/Footer/Footer';
import ListerSticky from '../../components/ListerSticky/ListerSticky';
import MobileNav from '../../components/MobileNav/MobileNav';
import NewStore from '../../components/NewStore/NewStore';
import { apiConstants } from '../../constants/apiConstants';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { ssrCommonMessages } from '../../localization/ssr/common';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setFilters,
  setInitialState,
  setSelectedQueryFilters,
} from '../../redux/listerSlice';
import { GWTabletWrapper } from '../../styles/window.style';
import { getUserInfoSSR } from '../../utils/cf';
import { trackEvent } from '../../utils/eventTracking';
import { getSelectedLocaleForSSR } from '../../utils/getSelectedLocaleForSSR';
import { WE_USER_EVENT_STORE_PAGE_FILTERS_APPLIED } from '../../utils/we';

interface Props {
  filters: FilterResponse;
}
const Index = (props: Props) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { cleverTap } = useAnalytics();

  const st = useAppSelector((state) => state.lister);

  const global = useAppSelector((state) => state.global);
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [sortDrawer, setSortDrawer] = useState<boolean>(false);
  /*
    First fetch the filters on first render
  */
  useEffect(() => {
    dispatch(setFilters(props.filters));
    // dispatch(fetchFilters());
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  /*
    when the router is ready
    and router.query updates, set the queryFilters in state
  */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const query = router.query;

    let x: any = createQueryFilter(query, st.filters);

    // trackEvent('frontend_event', {
    //   type: 'filter_change',
    //   eventData: {
    //     currentFilters: st.selectedQueryFilters,
    //     futureFilters: x,
    //     numberOfProducts: st.totalNumberOfProducts,
    //   },
    // });

    WE_USER_EVENT_STORE_PAGE_FILTERS_APPLIED(
      {
        excludeOutOfStock: x.eoos === 'true' ? true : false,
        genre: x.genre?.toString(),
        language: x.lang?.toString(),
        platform: x.platform?.toString(),
        priceMax: x.priceMax?.toString(),
        priceMin: x.priceMin?.toString(),
        productType: x.productType?.toString(),
        region: x.region
          ?.map((el: any) => getRegionFromRegionId(el))
          .toString(),
        sortBy: SortOptions.filter((el) => el.value === x.sort)[0]?.name,
      },
      cleverTap
    );

    dispatch(setSelectedQueryFilters(x));
  }, [router.query, cleverTap]);

  /*
    When the queryFilters updates the second time fetch the products
  */
  // useDidMountEffect(() => {
  //   if (!router.isReady) {
  //     return;
  //   }
  //   width && width > getNumFromResolutionConstant(WIDTH.tabletMax)
  //     ? dispatch(fetchProducts(st.selectedQueryFilters)).then(() => {})
  //     : dispatch(fetchProductsInfinite(st.selectedQueryFilters)).then(() => {});
  // }, [st.selectedQueryFilters, width]);

  useEffect(() => {
    try {
      if (Array.isArray(st.products)) {
        let totalValue: number = 0;
        let itemsArray: Array<GtagItemInterface> = [];
        /**
         * ak-TODO
         */
        for (let i = 0; i < st.products.length; i++) {
          if (typeof st.products[i].price === 'number') {
            totalValue = totalValue + +(st.products[i].price || 0);
          } else if (typeof st.products[i].price === 'string') {
            totalValue =
              totalValue + parseFloat(st.products[i].price?.toString() || '0');
          }
          // itemsArray.push({
          //   item_id: st.products[i].productSku,
          //   google_business_vertical: 'retail',
          // });
          itemsArray.push({
            item_id: st.products[i].productSku,
            item_name: st.products[i].title,
            // item_category2: st.products[i].platform || 'Other',
            // price:
            //   parseFloat(st.products[i].price?.toString() || '0') *
            //   (global.locationDetails?.multiplier || 1),
            // currency: global.locationDetails.currency || 'EUR',
            price: parseFloat(st.products[i].price?.toString() || '0'),
            currency: 'EUR',
            basePrice: parseFloat(st.products[i].price?.toString() || '0'),
            google_business_vertical: 'retail',
            quantity: 1,
          });
        }

        const eventData = {
          event: 'view_item_list',
          ecommerce: {
            // value: totalValue * (global.locationDetails?.multiplier || 1),
            // currency: global.locationDetails.currency || 'EUR',
            value: totalValue,
            currency: 'EUR',
            items: itemsArray,
          },
        };
        trackEvent('gtm_event', { eventData });
      }
    } catch {
      /**
       * sad
       */
    }
  }, [JSON.stringify(st.products)]);

  return (
    <>
      <Head>
        <title>Store - Driffle</title>
        <meta httpEquiv="last-modified" content={new Date().toDateString()} />
        <meta
          name="description"
          content="Buy products of different genres for different platforms at the best available prices"
        />
        <meta name="robots" content="index,follow" />
        <link
          rel="canonical"
          href={`https://driffle.com/${
            router.locale === 'en' ? '' : router.locale + '/'
          }store`}
        />

        {/* OG Meta */}
        <meta property="og:title" content="Store | Driffle" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="Buy products of different genres for different platforms at the best available prices"
        />
        <meta
          property="og:url"
          content={`https://driffle.com/${
            router.locale === 'en' ? '' : router.locale + '/'
          }store`}
        />
        <meta property="og:site_name" content="Driffle" />
        <meta
          property="og:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta property="fb:app_id" content="1787660858154522" />

        {/* Twitter Meta */}
        <meta property="twitter:title" content="Store | Driffle" />
        <meta
          property="twitter:description"
          content="Buy products of different genres for different platforms at the best available prices"
        />
        <meta
          property="twitter:url"
          content={`https://driffle.com/${
            router.locale === 'en' ? '' : router.locale + '/'
          }store`}
        />
        <meta
          property="twitter:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta name="twitter:card" content="summary" />
      </Head>
      <ListerWrapper>
        <Header />
        <Navbar />
        <BodyContainer>
          <ListerFlex>
            <WebSidebarContainer>
              <MemodFilterSidebar />
            </WebSidebarContainer>
            <FilterDrawer
              drawerState={filterDrawer}
              toggleDrawer={(x) => setFilterDrawer(x)}
            />
            {/* <SortDrawer
              drawerState={sortDrawer}
              toggleDrawer={(x) => setSortDrawer(x)}
            /> */}
            {/* {Object.keys(selectedQueryFilters).filter((cur) => cur !== 'page')
              .length > 0 ? (
              <WebLister />
            ) : ( */}
            <NewStore />
            {/* )} */}
          </ListerFlex>
        </BodyContainer>
        <ListerSticky
          toggleFilterDrawer={(x) => setFilterDrawer(x)}
          toggleSortDrawer={(x) => setSortDrawer(x)}
        />
        {/* <Footer /> */}
        {/* {width && width > getNumFromResolutionConstant(WIDTH.tabletMax) ? ( */}
        <GWTabletWrapper>
          <Footer />
        </GWTabletWrapper>
        {/* ) : null} */}
        <MobileNav />
      </ListerWrapper>
    </>
  );
};

export default React.memo(Index);

export const getServerSideProps: GetServerSideProps = async (context) => {
  // console.log(context.req.headers.referer);
  const localeData = await import(
    `../../localization/languages/${context?.locale ?? 'en'}.ts`
  );

  const ssrMessages = getSelectedLocaleForSSR(localeData.default, {
    ...ssrCommonMessages,
  });

  const userInfo = await getUserInfoSSR(context.req, context.res);

  let filters: FilterResponse | null = {
    genre: [],
    lang: [],
    productType: [],
    platform: [],
    region: [],
    worksOn: [],
  };

  await axios
    .get(apiConstants.search + '/products/v2/list?limit=0')
    .then((response) => {
      filters = response?.data['facetsDistribution'];
    })
    .catch((error) => {
      filters = null;
    });

  if (filters) {
    return {
      props: {
        locale: context?.locale ?? 'en',
        localeData: ssrMessages,
        reduxInitialState: userInfo,
        filters: filters,
      },
    };
  } else {
    return {
      notFound: true,
    };
  }
};
