import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext, useTheme } from 'styled-components';
import {
  ProductCardInterface,
  ProductSectionCardInterface,
} from '../../Interface/ProductCardInterface';
import { WIDTH } from '../../constants/screenResolution';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import {
  ArrowWrapper,
  NextArrowContainer,
  PrevArrowContainer,
  ProductSectionContainer,
  ProductSectionRow as ProductSectionRowContainer,
  ProductSectionTitle,
  ProductSectionWrapper,
  SkeletonContainer,
  SliderContainer,
} from './ProductSectionNewStyle';

import { Skeleton } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { HomepageCollectionsProductCardInterface } from '../../Interface/HomepageCollectionsInterface';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENT_VIEW_COLLECTION_CLICKED } from '../../utils/we';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import { OriginalImageContainer } from '../ProductCard/ProductCardGStyle';
import ProductSectionRow1 from './ProductSectionRow1';
import ProductSectionRow2 from './ProductSectionRow2';

interface IArrow {
  onClick?: () => void;
  disabled?: boolean;
}

export const SampleNextArrow = (props: IArrow) => {
  const { onClick, disabled } = props;
  const theme = useContext(ThemeContext);
  return (
    <NextArrowContainer onClick={onClick}>
      <ArrowWrapper>
        <MaskIcon
          url="/icons/arrow-right-chevron-24.svg"
          height="32px"
          color={theme.palette.text.t1}
          width="32px"
          margin="0"
          selected={true}
        />
      </ArrowWrapper>
    </NextArrowContainer>
  );
};

export const SamplePreviousArrow = (props: IArrow) => {
  const { onClick, disabled } = props;
  const theme = useContext(ThemeContext);
  return (
    <PrevArrowContainer onClick={onClick}>
      <ArrowWrapper style={{ transform: 'rotate(180deg)' }}>
        <MaskIcon
          url="/icons/arrow-right-chevron-24.svg"
          height="32px"
          width="32px"
          color={theme.palette.text.t1}
          margin="0"
          selected={true}
        />
      </ArrowWrapper>
    </PrevArrowContainer>
  );
};

// const ProductSectionRow1 = dynamic(() => import('./ProductSectionRow1'), {
//   loading: () => (
//     <ProductSectionRowContainer>
//       {[1, 2, 3, 4, 5, 6].map((cur, index) => (
//         <SkeletonContainer key={index}>
//           <Skeleton
//             variant="rectangular"
//             height="248px"
//             width="178px"
//             animation="wave"
//             sx={{ borderRadius: '8px' }}
//           />
//           <Skeleton
//             variant="text"
//             height="24px"
//             width="178px"
//             animation="wave"
//             key={cur}
//             sx={{ borderRadius: '8px' }}
//           />
//           <Skeleton
//             variant="text"
//             height="24px"
//             width="50%"
//             animation="wave"
//             key={cur}
//             sx={{ borderRadius: '8px', marginTop: '-8px' }}
//           />
//         </SkeletonContainer>
//       ))}
//     </ProductSectionRowContainer>
//   ),
// });
// const ProductSectionRow2 = dynamic(() => import('./ProductSectionRow2'), {
//   loading: () => (
//     <ProductSectionRowContainer>
//       {[1, 2, 3, 4, 5, 6].map((cur, index) => (
//         <SkeletonContainer key={index}>
//           <Skeleton
//             variant="rectangular"
//             height="248px"
//             width="178px"
//             animation="wave"
//             sx={{ borderRadius: '8px' }}
//           />
//           <Skeleton
//             variant="text"
//             height="24px"
//             width="178px"
//             animation="wave"
//             key={cur}
//             sx={{ borderRadius: '8px' }}
//           />
//           <Skeleton
//             variant="text"
//             height="24px"
//             width="50%"
//             animation="wave"
//             key={cur}
//             sx={{ borderRadius: '8px', marginTop: '-8px' }}
//           />
//         </SkeletonContainer>
//       ))}
//     </ProductSectionRowContainer>
//   ),
// });

interface Props {
  title: string;
  titleName: string;
  type: string;
  color: 'light' | 'dark';
  productCountToDisplay?: number;
  showMore?: boolean;
  showLoading?: boolean;
  initialProducts:
    | Array<ProductCardInterface>
    | Array<ProductSectionCardInterface>
    | Array<HomepageCollectionsProductCardInterface>;
  recommendationId?: string | null;
  collectionLink?: string;
}

const ProductSectionG = ({ initialProducts, ...props }: Props) => {
  const { width } = useWindowDimensions();
  const theme = useContext(ThemeContext);
  const [showRows, setShowRows] = useState<number>(!props.showMore ? 2 : 1);
  const [showCount, setShowCount] = useState<number>(6);

  const { messages } = useLocale();
  const { view_more_msg } = messages || {};

  useEffect(() => {
    if (width) {
      if (width < getNumFromResolutionConstant(WIDTH.laptopMin)) {
        setShowCount(4);
      } else if (width < getNumFromResolutionConstant(WIDTH.laptopMid)) {
        setShowCount(5);
      } else {
        setShowCount(6);
      }
    }
  }, [width]);

  const handleShowMore = useCallback(() => {
    setShowRows(showRows + 1);
  }, [showRows]);

  return (
    <ProductSectionContainer>
      <ProductSectionWrapper>
        {props.showLoading && initialProducts.length === 0 ? (
          <Skeleton
            variant="text"
            height="24px"
            width="250px"
            animation="wave"
            sx={{ borderRadius: '8px' }}
          />
        ) : (
          <ProductSectionTitle>{props.title}</ProductSectionTitle>
        )}
        <SliderContainer>
          <section id="home-page-section-slider">
            {props.showLoading && initialProducts.length === 0 ? (
              <ProductSectionRowContainer>
                {[1, 2, 3, 4, 5, 6].map((cur, index) => (
                  <SkeletonContainer key={index}>
                    <Skeleton
                      variant="rectangular"
                      height="248px"
                      width="178px"
                      animation="wave"
                      sx={{ borderRadius: '8px' }}
                    />
                    <Skeleton
                      variant="text"
                      height="24px"
                      width="178px"
                      animation="wave"
                      key={cur}
                      sx={{ borderRadius: '8px' }}
                    />
                    <Skeleton
                      variant="text"
                      height="24px"
                      width="50%"
                      animation="wave"
                      key={cur}
                      sx={{ borderRadius: '8px', marginTop: '-8px' }}
                    />
                  </SkeletonContainer>
                ))}
              </ProductSectionRowContainer>
            ) : (
              <ProductSectionRow1
                title={props.title}
                type={props.type}
                titleName={props.titleName}
                products={initialProducts.slice(0, showCount)}
                recommendationId={props.recommendationId}
              />
            )}
            {showRows > 1 && (
              <ProductSectionRow2
                title={props.title}
                type={props.type}
                titleName={props.titleName}
                products={initialProducts.slice(6, 6 + showCount)}
                recommendationId={props.recommendationId}
              />
            )}
          </section>
          {/* <Button buttonTheme="grey">View Collections</Button> */}

          {showRows < 2 && initialProducts.length !== 0 && (
            <>
              <div
                style={{
                  flex: 1,
                  background: theme.palette.divider.d1,
                  height: '1px',
                  width: 'calc(100% - 48px)',
                  margin: '0 auto',
                }}
              />
              <Button
                buttonTheme="grey"
                buttonType="open"
                onClick={handleShowMore}
                width="fit-content"
                height="20px"
                style={{
                  // margin: '4px auto 0',
                  color: theme.palette.text.primary,
                  height: '20px',
                  padding: 0,
                }}
              >
                {view_more_msg}
                <MaskIcon
                  url="/icons/arrow-right-chevron-24.svg"
                  height="20px"
                  width="20px"
                  style={{ transform: 'rotate(90deg)' }}
                  color={theme.palette.text.primary}
                  margin="0"
                  selected={true}
                />
              </Button>
            </>
          )}
        </SliderContainer>
      </ProductSectionWrapper>
    </ProductSectionContainer>
  );
};

const MemodProductSectionG = React.memo(ProductSectionG);
export default MemodProductSectionG;

export const ProductSectionCluster = ({ initialProducts, ...props }: Props) => {
  const router = useRouter();

  const theme = useTheme();

  const { width } = useWindowDimensions();
  const [showCount, setShowCount] = useState<number>(6);

  const { messages } = useLocale();
  const { see_all_msg } = messages || {};

  const { cleverTap } = useAnalytics();

  useEffect(() => {
    if (width) {
      if (width < getNumFromResolutionConstant(WIDTH.laptopMin)) {
        setShowCount(4);
      } else if (width < getNumFromResolutionConstant(WIDTH.laptopMid)) {
        setShowCount(5);
      } else {
        setShowCount(6);
      }
    }
  }, [width]);

  return (
    <ProductSectionContainer style={{ width: '100%' }}>
      <ProductSectionWrapper>
        {/* {props.showLoading && initialProducts.length === 0 ? (
          <Skeleton
            variant="text"
            height="28px"
            width="250px"
            animation="wave"
            sx={{ borderRadius: '8px' }}
          />
        ) : ( */}
        <ProductSectionTitle>{props.title}</ProductSectionTitle>
        {/* )} */}
        <SliderContainer>
          <section
            id="home-page-section-slider"
            style={{ paddingBottom: '0px', gap: '12px' }}
          >
            {props.showLoading && initialProducts.length === 0 ? (
              <ProductSectionRowContainer
                style={{
                  flexWrap: 'nowrap',
                }}
              >
                {Array.from(
                  Array(props?.productCountToDisplay || 6).keys()
                ).map((cur, index) => (
                  <SkeletonContainer key={index}>
                    <OriginalImageContainer>
                      <Skeleton
                        variant="rectangular"
                        height="100%"
                        width="100%"
                        animation="wave"
                        sx={{
                          borderRadius: '8px',
                          paddingTop: '140%',
                          background: theme.palette.background.bg3,
                        }}
                      />
                    </OriginalImageContainer>
                    <Skeleton
                      variant="text"
                      height="16px"
                      width="100%"
                      animation="wave"
                      sx={{
                        borderRadius: '8px',
                        background: theme.palette.background.bg3,
                        marginTop: '0px',
                      }}
                    />
                    <Skeleton
                      variant="text"
                      height="16px"
                      width="50%"
                      animation="wave"
                      sx={{
                        borderRadius: '8px',
                        background: theme.palette.background.bg3,
                        marginTop: '-12px',
                      }}
                    />
                  </SkeletonContainer>
                ))}
              </ProductSectionRowContainer>
            ) : (
              <ProductSectionRow1
                title={props.title}
                type={props.type}
                titleName={props.titleName}
                products={initialProducts.slice(0, showCount || 6)}
                recommendationId={props.recommendationId}
              />
            )}
            {props.showLoading && initialProducts.length === 0 ? (
              <Skeleton
                variant="rectangular"
                height="48px"
                width="100%"
                animation="wave"
                sx={{
                  borderRadius: '8px',
                  background: theme.palette.background.bg3,
                  // marginTop: '-12px',
                }}
              />
            ) : !props.collectionLink ? null : (
              <Link href={props.collectionLink}>
                <a>
                  <Button
                    buttonTheme="grey"
                    width="100%"
                    onClick={() => {
                      WE_USER_EVENT_VIEW_COLLECTION_CLICKED(
                        {
                          collectionName: props.title,
                          collectionLink: props.collectionLink ?? '',
                          pageUrl: window.location.href,
                        },
                        cleverTap
                      );
                      saveEventV3({
                        category: 'collection_card',
                        action: 'click',
                        label: props.titleName,
                        properties: 'view_collection',
                        value: [],
                        from: router,
                      });
                    }}
                  >
                    {see_all_msg}
                  </Button>
                </a>
              </Link>
            )}
          </section>
        </SliderContainer>
      </ProductSectionWrapper>
    </ProductSectionContainer>
  );
};
